@use "../../../styles/variables" as colors;

.wrapper {
  @keyframes rotate {
    0% {
      transform: rotate(0deg); /* Initial rotation */
    }

    100% {
      transform: rotate(360deg); /* Final rotation */
    }
  }
  height: 8rem;
  width: 100%;
  position: relative;
  margin-top: 3rem;
  .tracker-wrapper {
    position: absolute;
    top: 0;
    height: 8rem;
    width: 100%;

    .tracker {
      position: absolute;
      top: -2.25rem;
      width: 0.5rem;
      left: 0%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      transition: all 500 ease-in;

      .tracker-mark {
        font-size: 1.75rem;
        rotate: 180deg;
        color: #14feff;
        margin-bottom: -0.2rem;
      }
      .track-span {
        height: 8.75rem;
        width: 2px;
        background-color: #14feff;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .add-mark-box {
          position: relative;
        }
        .add-mark {
          font-size: 1.75rem;
          rotate: 180deg;
          color: #14feff;
          margin-bottom: -0.2rem;
        }
        .add-span {
          height: 8.75rem;
          width: 2px;
          background-color: #14feff;
        }
        .add-icon {
          position: absolute;
          top: 0.1rem;
          color: black;
        }
      }
      .question-index-mark {
        font-size: 1.75rem;
        rotate: 180deg;
        color: #14feff;
        margin-bottom: -0.2rem;
      }
      .question-index {
        height: 8.75rem;
        width: 2px;
        background-color: #14feff;
      }
    }
    .question-mark {
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -1.75rem;

      .question-number {
        width: 1.5rem;
        height: 1.5rem;

        text-align: center;
        background-color: #14feff;
        vertical-align: middle;
        .marker-icon {
          rotate: 180deg;
        }
      }
      span {
        width: 2px;
        height: 8rem;
        background-color: #14feff;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .timeline {
    height: 8rem;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &::-webkit-scrollbar {
      height: 8px;
    }
    p {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      .loading-icon {
        color: colors.$pirmary-green;
        font-size: 2.5rem;
        rotate: 180deg;
        animation: rotate 2s linear infinite;
        margin: auto;
      }
    }
    .images {
      height: 90%;
      display: flex;
      overflow: hidden;
      .frames {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        width: 100%;
        img {
          width: 8rem;
        }

        img {
          height: 80%;
        }
      }
    }
    .times {
      height: 15%;
      display: flex;
      justify-content: space-between;

      span {
        color: #6e6d77;
      }
    }
  }
}
@media (max-width: 1024px) {
  .wrapper {
    width: 97vw;
  }
}
