@use "../../../styles/variables" as colors;
.preview-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  inset: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.737);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;

  font-weight: 600;
  text-align: center;
  .levels-conatiner {
    color: white;
    margin-bottom: 1.5rem;
    select {
      padding: 0.5rem;
      border: none;
      border-radius: 5px 5px 5px 0;
      color: white;
      background-color: transparent;
      border: white 1px solid;
      font-size: 1rem;
      margin: auto;
      margin-left: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.743);
      }
      option {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  button {
    padding: 0.5rem;
    border: none;
    outline: none;
    background-color: transparent;
    border: white 1px solid;
    border-radius: 5px;
    color: white;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
    }
  }
  .player-container {
    position: relative;
    width: 50%;

    .timer {
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.5s linear;
    }

    .circle {
      background-color: black;
      height: 2.5rem;
      width: 2.5rem;
      text-align: center;
      display: grid;
      place-items: center;
      border-radius: 50%;
      .inner {
        height: 2rem;
        width: 2rem;
        background-color: black;
        color: white;
      }
    }
    .progress-bar {
      position: relative;
      width: 100%;
      margin-top: 1rem;
      height: 10px;
      border-radius: 5px;
      background-color: rgb(77, 72, 72);
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
