@use "../../styles/variables" as colors;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
.main-login {
  display: flex;
  height: 100vh;
  width: 100%;
  font-family: "Roboto Slab", serif;
  box-sizing: border-box;
  overflow-y: hidden;
  .login {
    width: 30%;
    background-color: colors.$main-bg;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // overflow-y: hidden;
    .onthehash {
      color: rgb(255, 255, 255);
      line-height: 2rem;

      span {
        &:first-child {
          font-size: 1.5rem;
        }
        &:last-child {
          font-size: 3rem;
          margin-left: 0.5rem;
        }
      }
    }
    .form {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      color: rgb(211, 211, 215);
      .login-title {
        font-size: 1.5rem;
      }
      .email {
        margin-bottom: 1rem;
      }
      input {
        margin-bottom: 0.5rem;
        border: none;
        border-bottom: 1px solid colors.$input-border;
        background: transparent;
        color: white;
        font-size: 1.2rem;
        &:focus {
          outline: none;
        }
        &::placeholder {
          // color: #ffffff;
          font-size: 1rem;
        }
      }
      .password-label-forgot {
        display: flex;
        justify-content: space-between;
        .forgot {
          a {
            font-size: 0.75rem;
            text-decoration: none;
            color: white;
          }
        }
      }
      .password {
        position: relative;
        input {
          width: 100%;
        }
        span {
          position: absolute;
          right: 1rem;
          bottom: 0.5rem;
          &:hover {
            cursor: pointer;
          }
          svg {
            font-size: 1.5rem;
          }
        }
      }
      .keep-login {
        span {
          margin-left: 0.3rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
      button {
        background-color: colors.$pirmary-green;
        border-radius: 0.25rem;
        padding: 0.75rem 0.5rem;

        color: black;
        outline: none;
        border: none;
        border: solid 1px transparent;
        &:hover {
          cursor: pointer;
          background-color: black;
          color: colors.$pirmary-green;
          border: solid;
          border-color: colors.$pirmary-green;
          border-width: 1px;
        }
        .loading-icon {
          font-size: 1.5rem;
          rotate: 180deg;
          animation: rotate 2s linear infinite;
          margin: auto;
        }
      }
      .sign-in {
        padding: 0.5rem;
        text-align: center;
        a {
          color: colors.$pirmary-green;
        }
      }
    }
    .error {
      color: red;
      font-size: 0.75rem;
    }
  }
  .image-container {
    display: flex;
    width: 70%;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
    }
    // .text-overlay {
    //   position: absolute;
    //   bottom: 10px;
    //   left: 0;
    //   h1 {
    //     font-weight: bolder;
    //     z-index: 10;
    //     color: white;
    //   }
    //   p {
    //     color: white;
    //     z-index: 10;
    //   }
    // }
  }
}

@media screen and (max-width: 1024px) {
  .main-login {
    justify-content: center;
    .login {
      width: 100vw;
      padding: 1rem;
    }
    .image-container {
      display: none;
    }
  }
}
