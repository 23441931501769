@use "../../../styles/variables" as colors;

.question-card {
  position: absolute;
  right: 0.25rem;
  bottom: 0.25rem;
  background-color: transparent;
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  div {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    p {
      display: flex;
      border-radius: 0.5rem;
      justify-content: flex-start;
      margin: 0;
      background-color: colors.$main-bg;
      align-items: center;
      gap: 1rem;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
      flex: 1;
      span {
        color: colors.$pirmary-green;
        border: colors.$pirmary-green 2px solid;
        border-radius: 50%;
        display: inline-block;
        height: 15px;
        width: 15px;
        padding: 0.15rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
      }
    }
  }
  p {
    background-color: colors.$main-bg;
    display: flex;
    border-radius: 0.5rem;
    padding: 0;
    padding-left: 1rem;
    color: white;
    padding: 0.5rem 0 0.5rem 1rem;
    margin: 0;
    font-size: 1rem;
  }
}
