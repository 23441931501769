@use "../../../styles/variables" as colors;
.uploader {
  height: 35rem;
  background-color: rgb(32, 32, 47);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  .upload {
    padding: 1.5rem;
    background-color: colors.$main-bg;
    border-radius: 50%;
    font-size: 3rem;
  }
  p {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  input {
    display: none;
  }
  .file-upload-label {
    margin-top: 20px;
    background-color: colors.$pirmary-green;
    color: black;
    border-radius: 3px;
    padding: 0.5rem 0.8rem;
    outline: none;
    white-space: nowrap;

    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
  }
  span {
    margin: 1rem;
  }
}

.player {
  position: relative;
  border: 1px solid lightgray;
  // height: 34rem;
  width: 100%;
  overflow: hidden;
  padding-top: 52.95%;
  height: 0;

  .loading-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(240, 248, 255, 0.438);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    .buffer-loading {
      color: colors.$pirmary-green;
      font-size: 3rem;
    }
  }
  #video-player
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-zone
  {
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1.5%;
    width: fill;
    height: 27%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // background-color: rgb(19, 19, 30);
    border: 1px solid white;
    color: colors.$pirmary-green;
    text-align: center;
    align-items: center;
    font-size: 100%;

    .no-zone-question
    {
      border: 1px solid white;
      background-color: rgb(19, 19, 30);
      height: 25%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }

    .no-zone-grid
    {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      gap: 1.5%;
      grid-row-start: 0%;
      height: 75%;
      width: 100%;
    }

    .no-zone-answer 
    {
      margin-top: 1.5%;
      text-align: center;
      border: 1px solid white;
      background-color: rgb(19, 19, 30);
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  .situation-bar {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 34rem;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .video-bar {
      background-color: rgb(19, 19, 30);
      border-top: 1px solid white;
      height: 2.5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      left: 2px;
      .score {
        color: colors.$pirmary-green;
      }
      div {
        border-right: 1px solid lightgray;
        width: calc(20% - 1px);
        padding: 0.5rem;
        text-align: center;
        vertical-align: middle;
      }
      .child1 {
        color: colors.$pirmary-green;
      }
      .child2 {
        display: flex;
        color: white;
        justify-content: space-evenly;
        align-items: center;
        span {
          .logo {
            height: 2rem;
            width: 2rem;
          }
        }
      }
      .child3 {
        display: flex;
        color: white;
        justify-content: space-evenly;
        align-items: center;
        span {
          .logo {
            height: 2rem;
            width: 2rem;
          }
        }
      }
      .child4 {
        color: darkgray;
      }
      .child5 {
        color: darkgray;
      }
    }
  }
}
.timecontrols {
  padding: 0;
    margin: 0;
    width: 100%;
  .slider-container {
    margin-top: 1rem;
    input {
      -webkit-appearance: none;
      width: 100%;
      height: 7px;
      background: grey;
      border-radius: 5px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 255, 0, 1) 0%,
        rgba(0, 255, 0, 0) 100%,
        rgba(0, 255, 0, 0) 100%
      );
      background-repeat: no-repeat;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #83d46a;
        cursor: pointer;
        box-shadow: 0 0 2px 0 #555;
      }
      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
  }
  .timers {
    display: flex;
    justify-content: space-between;
    color: white;
  }
}

.controls {
  display: flex;
  justify-content: center;
  * {
    margin: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }
}
