@use "../../styles/variables" as colors;
.quiz-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  flex: 1;
  background-color: colors.$main-bg;

  .quiz {
    padding: 2rem 5rem;
    display: flex;
    justify-content: space-around;
    flex: 1;

    .video-container {
      width: 70%;
    }
  }
}
@media (max-width: 1024px) {
  .quiz-container {
    .quiz {
      padding: 0;
    }
  }
}
