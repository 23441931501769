@use "../../../styles/variables" as colors;
.present-quetion-container {
  width: 42.5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .search-conatiner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    input {
      background-color: transparent;
      outline: none;
      border: 1px solid rgb(60, 59, 78);
      padding: 0.45rem 1rem;
      font-size: 1rem;
      color: white;
      padding-left: 2rem;
      &:hover,
      &:focus {
        border: colors.$pirmary-green solid 1px;
      }
    }
    .search {
      position: absolute;
      left: 1rem;
      color: white;
    }
    button {
      background-color: rgb(60, 59, 78);
      color: white;
      padding: 0.5rem 1rem;
      outline: none;
      border: transparent solid 1px;
      &:hover {
        cursor: pointer;
        background-color: colors.$pirmary-green;
        color: black;
      }
    }
  }
  .dropdowns-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;

    .content-box {
      color: white;
      width: 80%;
      div {
        background: gray;
        border: none;
        color: white;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          cursor: pointer;
        }
        .arrow {
          transition: all 0.5s ease-in-out;
        }
        .rotate {
          transform: rotate(90deg);
          transition: all 0.5s ease-in-out;
        }
      }
      ol {
        max-height: 0px;

        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: 0.5s all ease-in-out;
        li {
          border-bottom: dotted 1px gray;
          padding: 0.5rem 1rem;
          word-wrap: break-word;
          background-color: #272538;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .expand {
        max-height: 20rem !important;
        transition: 0.5s all ease-in-out;
      }
    }
  }
}
