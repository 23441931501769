@use "../../../styles/variables" as colors;
$color_1: #fff;
$color_2: #ffffff;
$background-color_1: #104068;
$background-color_2: #f75a1b;

.bar {
  margin-top: 1rem;
  height: 3rem;
  background-color: rgb(34, 33, 50);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 3rem;
  span {
    color: colors.$pirmary-green;
    margin-left: 1rem;
  }
  .buttons {
    width: 40rem;
    display: flex;

    .rookie-buttons {
      display: flex;
      width: 30rem;
      justify-content: space-evenly;
      gap: 1rem;
    }
    .checkbox {
      height: 2rem;
      width: 8rem;
      border-radius: 0.6rem;

      background-color: rgb(60, 59, 78);
      label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        background-color: #cccada;
        color: black;
        border-radius: 0.6rem;
      }
      input {
        display: none;
      }
    }

    // .all {
    //   background-color: rgb(60, 59, 78);
    //   border-radius: 0.6rem;
    //   box-shadow: -1px 1px 0 1px rgb(80, 78, 78);
    //   color: white;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   input {
    //     display: none;
    //     color: $color_1 !important;
    //   }
    //   label {
    //     width: 8rem;
    //     height: 2rem;
    //     display: block;
    //     text-align: center;
    //   }
    // }

    .activeAll {
      background-color: white;
      color: black;
    }
    button {
      width: 8rem;
      height: 2rem;
      color: white;
      border: none;
      box-shadow: -1px 1px 0 1px rgb(80, 78, 78);
      cursor: pointer;
      background-color: rgb(60, 59, 78);
      border-radius: 0.6rem;
    }
  }
}

.enter-question {
  margin-top: 1rem;
  background-color: rgb(32, 32, 47);
  display: flex;
  flex-direction: column;
  // width: 55.5%;
  width: 100%;
  .question {
    width: 100%;
    padding: 2rem 0rem 2rem 1rem;
    display: flex;
    flex-direction: row;

    .question-form {
      width: 55.5%;
    }
    .create-question {
      display: flex;
      align-items: center;
      span {
        align-items: center;
        &:first-child {
          border: 1px solid lightgray;
          padding: 0.2rem;
          border-radius: 50%;
          color: colors.$pirmary-green;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-items: center;
          width: 1.25rem;
          height: 1.25rem;
        }
        color: lightgray;
      }
    }

    .create-question-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: flex-start;
      .question-realanswer-container {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 0.75rem;
        span {
          color: red;
          font-size: 0.75rem;
        }

        div {
          display: flex;
          flex-direction: row;

          position: relative;
          display: flex;
          gap: 1rem;
          color: white;
          align-items: baseline;
          svg {
            font-size: 1.6rem;
          }

          .input-box {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 0;
          }

          label {
            text-align: right;
            color: white;
            min-width: 8rem;
          }
          input {
          }
        }
      }
      .options-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.75rem;
        color: white;
        margin-left: 6.1rem;
        div {
          display: flex;
          gap: 1rem;
          position: relative;
          flex-direction: row;
          align-items: baseline;
          .input-box {
            display: flex;
            flex-direction: column;
            gap: 0;
            align-items: flex-start;
          }
          span {
            color: red;
            font-size: 0.75rem;
          }
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: gray;
            color: white;
          }
        }
      }
      .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-right: 2.5rem;
        margin-left: 8.5rem;
        button {
          background-color: #cccad4;
          padding: 0.5rem 2rem;
          border: none;
          &:hover {
            cursor: pointer;
          }
          &:first-child {
            background-color: rgb(49, 48, 66);
            &:hover {
              background-color: #797786;
            }
            color: white;
          }
          &:last-child {
            &:hover {
              background-color: colors.$pirmary-green;
            }
          }
        }
      }
      .error-input {
        border: 1px solid red !important;
      }
      input {
        border-radius: 0.2rem;
        width: 100%;
        border: none;
        background-color: rgb(49, 48, 66);
        color: colors.$pirmary-green;
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px transparent solid;

        &:focus {
          outline: none;
          border: 1px solid colors.$pirmary-green;

          &::placeholder {
            visibility: hidden;
          }
        }
      }

      div {
        label {
          display: inline-block;

          text-align: center;
        }
        svg {
          margin-left: 0.7rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.rookie {
  margin-top: 1rem;
  height: 26rem;
  background-color: rgb(32, 32, 47);
  display: flex;
  .rookie-question {
    width: 60%;
    padding: 1rem;
    color: lightgray;
    .rookie-component {
      display: flex;
      margin-bottom: 3rem;
      div {
        .mark {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          color: colors.$pirmary-green;
          border: 1px solid lightgray;
          margin: 0;
        }
      }

      div {
        margin-left: 1rem;
        h4 {
          margin: 0;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .bar {
    width: 95vw;
    display: flex;
    justify-content: space-around;
    span {
      display: inline-block;
      width: 10rem;
      margin-left: 0;
    }
    .buttons {
      width: 100%;
      .rookie-buttons {
        width: 100%;
        .checkbox {
          border-radius: 0.5rem;
          width: auto;
          overflow: hidden;
          display: flex;
          label {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
  .enter-question {
    width: 98vw;
  }
}
