@use "../../../styles/variables" as colors;
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  inset: 0;
  z-index: 100;
  background-color: rgba(127, 123, 123, 0.59);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;

  font-weight: 600;
  text-align: center;
  .modal {
    width: 30%;
    background-color: colors.$main-bg;
    padding: 2rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  p {
    color: colors.$pirmary-green;
    margin: 0;
  }
  input {
    -webkit-appearance: none;
    width: 20%;
    height: 10px;
    background: grey;
    border-radius: 5px;
    background-color: rgb(232, 221, 221);
    background-repeat: no-repeat;
    transition: linear ease-in-out 1s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;

      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: red;
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: linear ease-in-out 1s;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;

      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: red;
      cursor: pointer;
      box-shadow: 0 0 2px 0 #555;
      transition: linear ease-in-out 1s;
    }
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;

      background: transparent;
      background-color: blue;
      transition: linear ease-in-out 1s;
    }
    &::-moz-range-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;

      background: transparent;
      background-color: blue;
      transition: linear ease-in-out 1s;
    }
  }
}
.alert {
  color: white;
  position: fixed;
  background-color: #151521;
  border: solid 1px colors.$pirmary-green;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 1.5rem 2rem;
  top: 6rem;
  right: -40rem;
  transition: all 500ms ease-in;

  .warn {
    display: flex;
    flex-direction: column;
    gap: 0;
    p {
      padding: 0;
      margin-top: 0;
    }
  }
  div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    button {
      padding: 0.5rem;
      background-color: rgb(8, 251, 85);
      border: none;
      color: rgb(4, 4, 4);
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        background-color: rgb(137, 137, 238);
      }
    }
  }
}
.show {
  right: 2rem;
}
.quizform-container {
  width: 25%;
  min-height: 100vh;
  margin-left: 2rem;
  .form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    .switch-container {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
    span {
      color: white;
      margin-left: 1em;
    }
    h4 {
      margin: 0;
      color: white;
      margin-right: 1rem;
      text-align: center;
    }
    input {
      display: none;
    }

    input[type="checkbox"] + label {
      position: relative;
      align-items: center;
      display: flex;
      &:hover {
        cursor: pointer;
      }
    }
    input[type="checkbox"] + label::before {
      content: "";
      width: 2em;
      height: 1em;
      background-color: hsl(0, 16%, 84%);
      border-radius: 1em;
      margin-right: 0.25em;
      transition: background-color 200ms ease-in-out;
    }
    input[type="checkbox"] + label::after {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      content: "";
      font-size: 0.5em;
      height: 0.9em;
      left: 0.2em;
      width: 1.8em;
      height: 1.8em;
      background-color: hsl(0, 80%, 60%);
      color: white;
      border-radius: 1em;
      transition: background-color 200ms ease-in-out,
        transform 200ms ease-in-out;
    }
    input[type="checkbox"]:checked + label::before {
      background-color: hsl(100, 70%, 90%);
    }
    input[type="checkbox"]:checked + label::after {
      content: "\2713";
      transform: translateX(100%);
      background-color: hsl(100, 70%, 60%);
    }
    // label {
    //   span {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 40%;
    //     height: 100%;
    //     border-radius: 50%;
    //     background-color: blueviolet;
    //     transition: all 1s ease;
    //   }
    // }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(178, 178, 184);
    gap: 0.35rem;
    label {
      color: #f1e7e7;
      // margin-bottom: 0.5rem;
      font-size: 0.75rem;
      margin-top: 1rem;
    }
    label.situation {
      display: flex;
      justify-content: space-between;
    }
    select {
      border: none;
      background-color: transparent;
      border-bottom: 1px solid colors.$input-border;
      color: white;

      &:focus {
        outline: none;
      }
      option {
        color: rgb(0, 255, 0);
        background-color: rgb(32, 32, 47);
      }
    }
    input {
      margin-bottom: 0.8rem;
      border: none;
      border-bottom: 1px solid colors.$input-border;
      background: transparent;
      color: white;
      &:focus {
        outline: none;
      }
    }
    .error {
      color: red;
      font-size: 0.75rem;
    }
    .errors {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    .multipleInputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      .field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.75rem;
        // border: red solid;
        width: 100%;

        .score {
          // width: 1.5rem
          input {
            width: 2rem;
            text-align: center;
          }
        }

        input {
          width: 3rem;
          margin-bottom: 0;
          font-size: 1rem;
          width: 100%;
        }
        fieldset {
          border: none;

          margin: 0;
          padding: 0;
          width: 100%;
          select {
            &:hover {
              cursor: pointer;
            }
            width: 100%;
            font-size: 1rem;
          }
        }
      }
    }

    .multiple {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      align-items: center;
      gap: 1.5rem;
      .field {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        input {
          width: 100%;
          color: white;
          font-size: 1rem;
          display: block;
          &:focus {
            outline: none;
          }
        }
      }
    }

    button {
      margin-top: 0.5rem;
      background-color: rgb(32, 32, 47);
      border: 1px solid white;
      border-radius: 0.1rem;
      padding: 0.5rem 0;
      color: white;
      outline: none;
      &:hover {
        background-color: colors.$pirmary-green;

        color: black;
        cursor: pointer;
      }
    }
  }
  .button-container {
    display: flex;
    gap: 1rem;
  }
  button {
    margin-top: 0.5rem;
    background-color: rgb(32, 32, 47);
    border: 1px solid white;
    border-radius: 0.1rem;
    padding: 0.5rem 0;
    width: 100%;
    color: white;
    outline: none;
    &:hover {
      background-color: colors.$pirmary-green;

      color: black;
      cursor: pointer;
    }
  }
  .alert {
    color: white;
    position: absolute;
    background-color: #151521;
    border: solid 1px colors.$pirmary-green;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 1rem;
    top: 6rem;
    right: -20rem;
    transition: all 500ms ease-in;
    div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      button {
        padding: 0.5rem;
        background-color: rgb(223, 91, 91);
        border: none;
        color: rgb(4, 4, 4);
        &:hover {
          cursor: pointer;
        }
        &:first-child {
          background-color: rgb(137, 137, 238);
        }
      }
    }
  }
  .show {
    right: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
}
