@use "../../../styles/variables" as colors;
.navbar-links {
  .navbar {
    max-width: 100vw;
    background-color: colors.$main-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    .onthehash-navbar {
      color: rgb(255, 255, 255);
      line-height: 1.5rem;
      margin-left: 4rem;

      span {
        &:first-child {
          font-size: 1rem;
        }

        &:last-child {
          font-size: 2rem;
          margin-left: 0.5rem;
        }
      }
    }

    .user {
      color: white;
      line-height: 0.2rem;
      margin-right: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: inline-block;
        &:first-child {
          font-size: 0.8rem;
          line-height: 1rem;
        }
        .creator {
          color: lightgray;
          font-size: 0.6rem;
        }
      }
      img {
        padding: 0 0.5rem;
        height: 2.5rem;
        width: 2.5rem;
        object-fit: cover;
        border-radius: 50%;
        &:hover {
          cursor: pointer;
        }
      }
      .logout {
        cursor: pointer;
      }
    }
  }

  .links {
    height: 3rem;
    background-color: rgb(60, 59, 77);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.25rem;
    .link {
      padding: 0 1rem;
      color: white;
      text-decoration: none;
    }
    .link.active {
      color: rgb(0, 255, 0);
    }
  }
}
