@use "../../styles/variables" as colors;

.link-container {
  height: calc(100vh - 135px);
  width: 100vw;
  flex: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("https://media.istockphoto.com/id/1056806530/photo/huge-rugby-stadium-with-fans-and-green-grass.jpg?s=612x612&w=0&k=20&c=weS2eBKdelupv7jEqhlMXNA4JWsDIfCT-pOFlRj6ouY=");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .link {
    flex: 1;

    .link-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;

      .content {
        margin-top: 1rem;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .search-container {
          background-color: transparent;
          display: flex;
          justify-content: space-between;
          div {
            position: relative;
            background-color: transparent;
            color: rgb(206, 198, 198);
            border-radius: 0.25rem;
            margin-bottom: 0.5rem;
            display: flex;
            gap: 15px;
            cursor: pointer;
            align-items: center;
            transition: width 1000ms;
            .search-icon {
              position: absolute;
              left: 10px;
            }
            input {
              background-color: transparent;
              padding: 0.5rem 1.5rem;
              padding-left: 2.5rem;
              width: 50%;
              font-size: 1rem;
              border: 1px solid rgb(149, 149, 149);
              border-radius: 5px;
              color: white;
              &::placeholder {
                color: lightgray;
                font-size: 0.9rem;
              }
              &:focus {
                width: 80%;
                outline: none;
                transition: width 1000ms;
              }
              transition: width 1000ms;
            }
          }
          .add-link {
            background-color: transparent;
            color: colors.$pirmary-green;
            border: 1px solid colors.$pirmary-green;
            border-radius: 0.25rem;
            margin-bottom: 0.5rem;
            cursor: pointer;
            padding: 0.5rem;
          }
        }

        .table-container {
          // max-height: calc();
          height: calc(100vh - 15rem);
          overflow-y: auto;
          width: 100%;
          .table {
            color: white;
            border-collapse: collapse;
            border-radius: 1em;
            width: 100%;
            table-layout: fixed;
            thead {
              background-color: #1d2430;
              position: sticky;
              top: 0;
              z-index: 1;
              th {
                &:first-child {
                  padding-left: 1.5rem;
                }
              }
            }
            tr {
              text-align: left;
              .sorting {
                margin-left: 0.2rem;
                vertical-align: middle;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            th,
            td {
              padding: 0.75rem;
            }
            tbody {
              background-color: #1d2430d2;
              overflow-y: auto;
              width: 100%;
              .link {
                a {
                  color: white;
                  text-decoration: none;
                }

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              td {
                .link {
                  color: white;
                }
                &:first-child {
                  padding-left: 1.5rem;
                }
                &:last-child {
                  display: flex;
                  position: relative;
                  gap: 0.5rem;
                  align-items: center;

                  // &:hover {
                  //   cursor: pointer;
                  // }
                }
                .dots {
                  border-radius: 0.25rem;
                  padding: 0.2rem;

                  // &:hover {
                  //   background-color: rgba(247, 240, 240, 0.544);
                  // }
                }
                .active {
                  background-color: rgba(247, 240, 240, 0.544);
                }
                .plays {
                  margin-right: 0.5rem;
                }
                .edit,
                .delete {
                  &:hover {
                    cursor: pointer;
                  }
                  color: rgb(128, 128, 233);
                }
                .delete {
                  color: rgb(231, 38, 38);
                }
              }
              .dropdowns {
                position: absolute;
                width: 5rem;
                border-radius: 0.5rem 0 0.5rem 0.5rem;
                display: flex;
                top: 1rem;
                flex-direction: column;
                background-color: white;
                border: 1px solid black;
                color: black;
                z-index: 1;
                left: 0;
                align-items: center;
                span {
                  padding: 0.2rem 0;
                  &:hover {
                    cursor: pointer;
                    display: inline-block;
                    background-color: rgba(21, 21, 34, 0.392);
                    width: 100%;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .alert {
    color: white;
    position: absolute;
    background-color: #151521;
    border: solid 1px colors.$pirmary-green;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 1rem;
    top: 6rem;
    right: -20rem;
    transition: all 500ms ease-in;
    div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      button {
        padding: 0.5rem;
        background-color: rgb(223, 91, 91);
        border: none;
        color: rgb(4, 4, 4);
        &:hover {
          cursor: pointer;
        }
        &:first-child {
          background-color: rgb(137, 137, 238);
        }
      }
    }
  }
  .show {
    right: 2rem;
  }
}
