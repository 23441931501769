*::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgb(30, 29, 45); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(211, 211, 211); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(30, 29, 45); /* creates padding around scroll thumb */
}
@keyframes skeleton-animation {
  0% {
    background-color: #222a38;
  }
  100% {
    background-color: #c1c9d7;
  }
}
.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.loading-icon {
  font-size: 1.5rem;
  rotate: 180deg;
  animation: rotate 2s linear infinite;
  margin: auto;
}
.skeleton {
  border-bottom: 1px solid #746b6b;
}
.skeleton-data {
  animation: skeleton-animation linear 1s infinite alternate;
}
.upload-progress {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.433);
  display: flex;
  justify-content: center;
  align-items: center;
}
.disable-scroll {
  overflow: hidden;
}
.Toastify__toast {
  background-color: #151521;
  color: white;
}
.Toastify__close-button > svg {
  color: white;
}
