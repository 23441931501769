@use "../../styles/variables" as colors;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
.main-register {
  display: flex;
  height: 100vh;
  width: 100%;
  font-family: "Roboto Slab", serif;
  box-sizing: border-box;

  .forget-password {
    width: 30%;
    background-color: colors.$main-bg;
    padding: 1.5rem 4rem;
    overflow-y: scroll;
    .onthehash {
      color: rgb(255, 255, 255);
      line-height: 2rem;

      span {
        &:first-child {
          font-size: 1.5rem;
        }
        &:last-child {
          font-size: 2rem;
          margin-left: 0.5rem;
        }
      }
    }
    .form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      color: rgb(211, 211, 215);
      .login-title {
        font-size: 1.5rem;
      }
      span {
        color: red;
        font-size: 0.8rem;
      }

      input {
        margin: 0.6rem 0;
        border: none;
        border-bottom: 1px solid colors.$input-border;
        background: transparent;
        color: white;
        font-size: 1.2rem;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 1rem;
        }
      }

      button {
        background-color: colors.$pirmary-green;
        border-radius: 0.25rem;
        padding: 0.75rem 0.5rem;
        margin-top: 0.5rem;
        color: black;
        outline: none;
        border: none;
        font-weight: 700;
        &:hover {
          cursor: pointer;
          background-color: black;
          color: colors.$pirmary-green;
          border: solid;
          border-color: colors.$pirmary-green;
          border-width: 1px;
        }
      }
      .signin {
        padding: 0.5rem;
        text-align: center;
        a {
          color: colors.$pirmary-green;
        }
      }
    }
  }
  .image-container {
    display: flex;
    width: 70%;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-register {
    justify-content: center;
    .forget-password {
      width: 100%;
      padding: 1rem;
    }
    .image-container {
      display: none;
    }
  }
}
