@use "../../styles/variables" as colors;
.profile-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .profile-content {
    flex: 1;

    background-image: url("https://media.istockphoto.com/id/1056806530/photo/huge-rugby-stadium-with-fans-and-green-grass.jpg?s=612x612&w=0&k=20&c=weS2eBKdelupv7jEqhlMXNA4JWsDIfCT-pOFlRj6ouY=");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .profile-form {
      width: 30%;
      padding-bottom: 1.25rem;

      margin-top: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgb(37, 39, 54, 0.9);
      position: relative;
      color: white;

      form {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 4.5rem;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        .image-container {
          position: absolute;
          top: -4rem;
          height: 10rem;
          width: 10rem;
          img {
            border-radius: 50%;
            object-fit: cover;
            height: 7rem;
            width: 7rem;
          }
          label {
            cursor: pointer;
            border-radius: 5px;
            padding: 02px;
            background-color: rgba(36, 38, 48, 0.9);
            margin-left: 4.75rem;
            margin-top: -1.75rem;
            box-shadow: 2px 2px 0.5px rgba(246, 239, 239, 0.753);
            svg {
              font-size: 1.25rem;
              rotate: -90deg;
            }

            input {
              display: none;
            }
          }
        }

        label {
          margin-bottom: 0.3rem;
          align-self: flex-start;
          font-size: 0.8rem;
        }
        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 0.3rem;
          border: none;
          background: transparent;
          span {
            color: red;
            font-size: 0.75rem;
          }
          input {
            width: 90%;
            border: none;
            border-bottom: 1px solid lightgray;
            outline: none;
            background: transparent;
            color: colors.$pirmary-green;
            font-size: 1rem;
          }
        }
        button {
          background-color: colors.$pirmary-green;
          outline: none;
          border: 1px solid transparent;
          width: 50%;
          margin: auto;
          padding: 0.5rem;
          margin-top: 0.5rem;
          &:hover {
            background-color: colors.$main-bg;
            color: white;
            cursor: pointer;
            border: solid 1px colors.$input-border;
          }
        }
        div {
          display: flex;
          flex-direction: column;
          position: relative;
        }

        .socials {
          ol {
            display: flex;
            gap: 0.5rem;
            flex-direction: column;
            position: relative;
            padding-left: 1rem;
            margin: 0;
            margin-bottom: 1rem;
            svg {
              color: red;
              margin-left: 2rem;
              &:hover {
                cursor: pointer;
              }
            }
          }
          div {
            position: relative;
            svg {
              position: absolute;
              right: 2rem;
              bottom: 0.5rem;
              font-size: 1.2rem;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      button {
        background-color: colors.$pirmary-green;
        outline: none;
        border: 1px solid transparent;
        width: 50%;
        margin: auto;
        padding: 0.5rem;
        margin-top: 0.5rem;
        &:hover {
          background-color: colors.$main-bg;
          color: white;
          cursor: pointer;
          border: solid 1px colors.$input-border;
        }
      }
    }
  }
}
