@use "../../../styles/variables" as colors;

.add-user-container {
  display: flex;
  justify-content: center;
  background-image: url("https://media.istockphoto.com/id/1056806530/photo/huge-rugby-stadium-with-fans-and-green-grass.jpg?s=612x612&w=0&k=20&c=weS2eBKdelupv7jEqhlMXNA4JWsDIfCT-pOFlRj6ouY=");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  color: lightgray;
  height: 100%;
  .header-cross {
    display: flex;
    justify-content: space-between;
    h3 {
      color: lightgray;
    }
    .close {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .add-new-user {
    background-color: rgb(21, 21, 34, 0.9);
    // height: 80%;
    width: 35%;
    padding: 1rem 3rem 1rem;

    .add-user-form {
      display: flex;
      flex-direction: column;
      .error {
        color: red;
        font-size: 0.75rem;
      }
      label {
        font-size: 0.75rem;
      }
      .social-links {
        list-style: none;

        padding: 0;
        li {
          a {
            color: rgb(0, 255, 0);
          }
        }
      }
      div {
        display: flex;
        flex-direction: column;
        position: relative;
        span {
          position: absolute;
          right: 1rem;
          bottom: 0.5rem;
          &:hover {
            cursor: pointer;
          }
          svg {
            font-size: 1.5rem;
          }
        }
      }
      input {
        margin-bottom: 0.5rem;
        border: none;
        border-bottom: 1px solid rgb(167, 155, 155);
        background: transparent;
        color: colors.$pirmary-green;
        font-size: 1rem;
        &:focus {
          outline: none;
        }
      }
      button {
        background-color: colors.$pirmary-green;
        color: black;
        padding: 0.3rem;
        font-size: 1rem;
        border-radius: 0.1rem;
        border: solid transparent 1px;
        width: 50%;
        margin: auto;
        margin-top: 1rem;
        &:hover {
          cursor: pointer;
          background-color: transparent;
          border: solid 1px;
          border-color: colors.$pirmary-green;
          color: colors.$pirmary-green;
        }
      }
    }
  }
}
